class Pagination{
    showPaging(data,page){
        const paginate = document.querySelector('.paginate')

        page = page ? page:1
        let  perPage = 4
        let pageHtml = this.paginate(data.length,perPage,page,'#')
        paginate.innerHTML = pageHtml
    }

    static async paginate(totalRows,maxRows,page,uri='#'){

        let adjacents = 2;
        let pageNum = parseInt(page) - 1;
        let startRow = pageNum * maxRows;
        
        // prevrec = startRow - 1;
        // nextrec = startRow + 1;

        page = page == 0 ? 1:page ;

        let prev = parseInt(page) - 1;
        let next = parseInt(page) + 1;
        let lastpage = Math.ceil(totalRows / maxRows);
        let totalPages = lastpage - 1;
        let counter = 0
        let x = 0
        let pagination = "";

        if (lastpage > 1) {
            pagination += `<div class="pagination">`;
            //previous button
            if (page > 1) {
                pagination += `<a  data-page="${prev}" href="${uri}">« </a>`;
            } else {
                pagination += `<span class="disabled">« </span>`;
            }

            //pages
            if (lastpage < 7 + (adjacents * 2)) //not enough pages to bother breaking it up
            {
                for (counter = 1, x = 0; (counter <= lastpage); counter++, x++) {

                    if (counter == page) {
                        pagination += `<span class="current">${counter}</span>`;
                    } else {
                        pagination += `<a  data-page="${counter}" href="${uri}">${counter}</a>`;
                    }

                }
            } else if (lastpage > 5 + (adjacents * 2)) //enough pages to hide some
            {
                //close to beginning; only hide later pages
                
                if (page < 1 + (adjacents * 2)) {
                    
                    for (counter = 1, x = 0; (counter < 4 + (adjacents * 2)); counter++, x++) {

                        if (counter == page) {
                            pagination += `<span class="current">${counter}</span>`;
                        } else {
                            pagination += `<a  data-page="${counter}" href="${uri}">${counter}</a>`;
                        }

                    }
                    pagination += `...`;
                    x = totalPages - 1;
                    pagination += `<a  data-page="${totalPages}" href="${uri}">${totalPages}</a>`;
                    x = lastpage - 1;
                    pagination += `<a  data-page="${lastpage}" href="${uri}">${lastpage}</a>`;
                }else if ((lastpage - (adjacents * 2) > page) && (page > (adjacents * 2))) {
                    //in middle; hide some front and some back
                    pagination += `<a  data-page="1" href="${uri}">1</a>`;
                    pagination += `<a  data-page="2" href="${uri}">2</a>`;
                    pagination += `...`;

                    for (counter = (page - adjacents), x = (startRow - adjacents); (parseInt(counter) <= (parseInt(page) + parseInt(adjacents))); counter++, x++) {
                        // console.log('counter='+counter,'page='+page , 'adjacents='+adjacents,parseInt(page) + parseInt(adjacents));
                        if (counter == page) {
                            pagination += `<span class="current">${counter}</span>`;
                        } else {
                            pagination += `<a  data-page="${counter}" href="${uri}">${counter}</a>`;
                        }

                    }
                    pagination += `...`;
                    x = totalPages - 1;
                    pagination += `<a  data-page="${totalPages}" href="${uri}">${totalPages}</a>`;
                    x = lastpage - 1;
                    pagination += `<a  data-page="${lastpage}" href="${uri}">${lastpage}</a>`;
                }else {
                //close to end; only hide early pages
                    pagination += `<a  data-page="1" href="${uri}">1</a>`;
                    pagination += `<a  data-page="2" href="${uri}">2</a>`;
                    pagination += `...`;
                    x = lastpage - (2 + (adjacents * 2)) - 1;
                    for (counter = lastpage - (2 + (adjacents * 2)), x; counter <= lastpage; counter++, x++) {
                        if (counter == page) {
                            pagination += `<span class="current">${counter}</span>`;
                        } else {
                            pagination += `<a  data-page="${counter}" href="${uri}">${counter}</a>`;
                        }

                    }
                }
            }
            //next button
            if ((page < counter - 1) && (!(startRow >= totalRows - maxRows))) {
                pagination += `<a  data-page="${next}" href="${uri}">»</a>`;
            } else {
                pagination += `<span class="disabled">»</span>`;
            }

            pagination += "</div>";
        }
        return pagination;
    }
}

export {Pagination}